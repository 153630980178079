import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../services/api"; // Asegúrate de que la ruta sea correcta
import "bootstrap-icons/font/bootstrap-icons.css"; // Asegúrate de incluir los iconos de Bootstrap
import Swal from "sweetalert2";
const ListWorkers = () => {
  const [workers, setWorkers] = useState([]);
  const [documentTitles, setDocumentTitles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate(); // Hook para redireccionar

  useEffect(() => {
    const fetchWorkers = async () => {
      try {
        const response = await api.get("/workers");
        setWorkers(response.data);

        // Obtener los títulos de los documentos
        const titles = [...Array(10).keys()].map((i) => {
          const document = response.data[0]?.documents.find((doc) => doc.document_id === (i + 1).toString());
          return document ? document.document_title : `Document ${i + 1}`;
        });
        setDocumentTitles(titles);

        setLoading(false);
      } catch (error) {
        setError("Failed to fetch workers");
        setLoading(false);
      }
    };

    fetchWorkers();
  }, []);

  const getCellStyle = (exists, expired) => {
    if (exists === "no" || exists == "") return {};
    return {
      backgroundColor: expired == "1" ? "red" : "green",
      color: "white"
    };
  };

  const handleEdit = (id) => {
    navigate(`/dashboard/editWorker/${id}`);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "No podrás revertir esto.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Realizar la solicitud POST para eliminar el trabajador
          const response = await api.post(`/workers/deleteWorker`, { id });

          if (response.data.success) {
            // Actualizar la lista de trabajadores eliminando el que fue borrado
            setWorkers((prevWorkers) => prevWorkers.filter((worker) => worker.id !== id));

            Swal.fire("Eliminado", "El trabajador ha sido eliminado.", "success");
          } else {
            Swal.fire("Error", "Hubo un error al intentar eliminar el trabajador.", "error");
          }
        } catch (error) {
          console.error("Error al eliminar el trabajador:", error);
          Swal.fire("Error", "Ocurrió un error inesperado al eliminar el trabajador.", "error");
        }
      }
    });
  };

  const handleAddWorker = () => {
    navigate("/dashboard/addWorker");
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredWorkers = workers.filter(
    (worker) =>
      worker.dni.includes(searchTerm) ||
      worker.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      worker.last_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) return <div>Cargando...</div>;
  if (error) return <div className="alert alert-danger">{error}</div>;

  return (
    <div className="container-fluid pb-5 ">
      <div className="mb-4">
        <h2>Registro Personal</h2>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div className="input-group w-50">
          <input
            type="text"
            className="form-control"
            placeholder="Buscar por  DNI, Nombre, o Apellido"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <button className="btn btn-outline-secondary">
            <i className="bi bi-search"></i>
          </button>
        </div>
        <button className="btn btn-primary" onClick={() => handleEdit(0)}>
        <i class="bi bi-plus"></i>   Agregar Trabajador
        </button>
      </div>
      <div className="table-responsive">
        <table className="table table-condensed table-striped table-bordered">
          <thead>
            <tr>
              <th>ID</th>
              <th>Nombre Completo</th>
              <th>Apellido</th>
              <th>Puesto de trabajo</th>
              <th>Empresa</th>
              <th>DNI</th>
              {/* Add headers for each document dynamically */}
              {documentTitles.map((title, index) => (
                <th key={index}>
                  <small>{title}</small>
                </th>
              ))}
              <th></th>
              <th>Acciones</th> {/* New header for action buttons */}
            </tr>
          </thead>
          <tbody>
            {filteredWorkers.map((worker) => (
              <tr key={worker.id}>
                <td>{worker.id}</td>
                <td>{worker.first_name}</td>
                <td>{worker.last_name}</td>
                <td>{worker.job_title}</td>
                <td>{worker.company}</td>
                <td>{worker.dni}</td>

                {/* Render each document */}
                {documentTitles.map((_, index) => {
                  const document = worker.documents.find((doc) => doc.document_id === (index + 1).toString());
                  const exists = document ? document.document_exists : "no";
                  const expired = document ? document.document_expired : "0";
                  return (
                    <td key={index} style={getCellStyle(exists, expired)}>
                      {exists === "no" ? "No" : expired === "1" ? "Vencido" : "Vigente"}
                    </td>
                  );
                })}

                <td style={getCellStyle("Si", worker.no_apto)}>{worker.no_apto == "0" ? "Apto" : "No Apto"}</td>
                <td>
                  <button className="btn btn-info btn-sm me-2" onClick={() => handleEdit(worker.id)}>
                    <i className="bi bi-pencil"></i>
                  </button>
                  <button className="btn btn-danger btn-sm" onClick={() => handleDelete(worker.id)}>
                    <i className="bi bi-x"></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ListWorkers;
