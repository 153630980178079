import axios from "axios";
import qs from "qs";
axios.defaults.withCredentials = true;
// Crea una instancia de axios con el token en el encabezado
const baseURL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL 
});

// Función para generar un nombre aleatorio
const generateRandomContextId = () => {
  return `context_${Math.random().toString(36).substring(2, 15)}`;
};

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authToken");
    const contextId = generateRandomContextId();

    if (config.method === "get") {
      //config.params = { ...config.params, contextId };
      //config.params = { ...config.params};
    } else if (config.method === "post") {
      if (config.data instanceof FormData) {
        // Añadir contextId como un campo en FormData
        config.data.append("contextId", contextId);
      } else {
        // Serializar los datos para POST requests si no es FormData
        const data = qs.stringify({
          ...config.data,
          contextId
        });
        config.data = data;
        config.headers["Content-Type"] = "application/x-www-form-urlencoded";
      }
    }

        //cuando comento esto funciona
    // Excluir el token en la solicitud de inicio de sesión
    if (config.url.includes("/login")) {
        // No añadir el token en la solicitud de login
        if (config.method === "post") {
          config.headers.Authorization = null;
        }
      } else if (token) {
        //  config.headers.Authorization = `Bearer ${token}`;
        config.headers.Authorization = `Bearer ${token}`;
      }

    return config;
  },
  (error) => Promise.reject(error)
);

export default api;
