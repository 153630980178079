import React, { useState } from "react";
import api from "../services/api"; // Asegúrate de que la ruta sea correcta
import { useNavigate } from "react-router-dom";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState(null);
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Hook para redireccionar
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await api.post("/auth", { email, password });

      if (response.status === 200) {
        setToken(response.data.token);
        setError("");
        localStorage.setItem("authToken", response.data.token);
        localStorage.setItem("nameUser", response.data.nameUser); // Guardar el nombre del usuario
        localStorage.setItem("roleUser", response.data.role); 
        // Redirigir al dashboard después de un inicio de sesión exitoso
        navigate("/dashboard");
      }
    } catch (error) {
      setError("Invalid email or password");
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="card shadow p-4" style={{ width: "100%", maxWidth: "400px" }}>
        <div className="text-center mb-3">
          <i className="bi bi-lock-fill" style={{ fontSize: "3rem" }}></i>
        </div>
        <h2 className="text-center mb-4">Sistema de Registro</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email:
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              Clave:
            </label>
            <input
              type="password"
              className="form-control"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary w-100">
            Iniciar Sesión
          </button>
        </form>
        {error && (
          <div className="alert alert-danger mt-3" role="alert">
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
