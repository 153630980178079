import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../services/api"; // Asegúrate de que la ruta sea correcta
import Swal from "sweetalert2"; // Importa SweetAlert2
import MyDocument from "./MyDocument";
import { useLocation } from "react-router-dom";
const Documents = () => {
  const { id } = useParams(); // Obtiene el ID del trabajador desde la URL
  const navigate = useNavigate(); // Hook para la navegación programática
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [isEdited, setIsEdited] = useState(false); // Estado global de edición
  
  const location = useLocation();
  const { firstName, lastName } = location.state || {};

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await api.get(`/workers/getDocuments/${id}`); // Ajusta la ruta del endpoint según tu API
        setDocuments(response.data);
        setLoading(false);
      } catch (error) {
        setError("Failed to fetch documents");
        setLoading(false);
      }
    };

    fetchDocuments();
  }, [id]);

  // Función que actualiza el estado de edición de algún documento
  const handleIsEditedChange = (edited) => {
    setIsEdited(edited);
  };
  const handleDelete = async (docId) => {
    try {
      await api.delete(`/workers/deleteDocument/${docId}`); // Ajusta la ruta del endpoint según tu API
      setDocuments(documents.filter((doc) => doc.id !== docId));
      Swal.fire({
        icon: "success",
        title: "Deleted",
        text: "Document deleted successfully"
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to delete document"
      });
    }
  };

  const handleNavigate = (path) => {
    if (isEdited) {
      // Mostrar advertencia si hay cambios sin guardar
      Swal.fire({
        title: "Cambios sin guardar",
        text: "Tienes cambios sin guardar, ¿deseas continuar?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, continuar",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(path); // Navegar solo si el usuario confirma
        }
      });
    } else {
      navigate(path); // Si no hay cambios, navegar directamente
    }
  };
  if (loading) return <div>Cargando...</div>;
  if (error) return <div className="alert alert-danger">{error}</div>;

  return (
    <div className="container mt-4 pb-5 ">
      <h3>Documentos del trabajador  #{id}</h3>
      <div>
      {firstName && lastName && (
        <h2 className="mb-3">
            {firstName} {lastName}
        </h2>
      )}
      </div>
      <table className="table mytable table-hover table-bordered">
        <thead>
          <tr>
            <th>#</th>
            <th>Documento</th>
            <th>Si</th>
            <th>No</th>
            <th>Fecha Inicio</th>
            <th>Fecha Fin</th>
            <th>Subir Archivo</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {documents.length > 0
            ? documents.map((doc) => <MyDocument key={doc.id}
            onIsEditedChange={handleIsEditedChange}
             workerId={id} documentId={doc.id} />)
            : "No existen documentos"}
        </tbody>
      </table>
      <div className="d-flex justify-content-end mt-4">
        <button className="btn btn-primary me-2"  onClick={() => handleNavigate("/dashboard")}>
          <i className="bi bi-house-door"></i> Finalizar
        </button>
        <button className="btn btn-secondary"  onClick={() => handleNavigate(-1)}>
          <i className="bi bi-arrow-left"></i> Volver
        </button>
      </div>
    </div>
  );
};

export default Documents;
