import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../services/api'; // Asegúrate de que la ruta sea correcta
import Swal from 'sweetalert2'; // Asegúrate de tener SweetAlert2 instalado
 
const ListUsers = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    api.get('/workers/users')
      .then(response => {
        if (response.data.success) {
          setUsers(response.data.users);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Acceso denegado',
            text: response.data.message || 'No tienes permisos para acceder a esta sección.',
          }).then(() => {
            // Opcionalmente, redirige al usuario a otra página si no tiene permisos
            window.location.href = '/dashboard'; // O la ruta a donde quieras redirigir
          });
        }
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Hubo un problema al obtener los usuarios.',
        });
      });
  }, []);

  const handleDelete = (id) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "No podrás revertir esto",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        // Si el usuario confirma, ejecutar la eliminación
        api.post(`/workers/users/${id}/delete`, { st: 0 })
          .then(response => {
            if (response.data.success) {
              Swal.fire(
                'Eliminado',
                'El usuario ha sido eliminado',
                'success'
              );
              // Actualizar el estado de los usuarios
              setUsers(users.map(user => user.id === id ? { ...user, st: 0 } : user));
            } else {
              Swal.fire(
                'Error',
                response.data.message || 'No se pudo eliminar el usuario.',
                'error'
              );
            }
          })
          .catch(error => {
            Swal.fire(
              'Error',
              'Hubo un problema al eliminar el usuario',
              'error'
            );
          });
      }
    });
  };
  
  return (
    <div>
      <h2>Lista de Usuarios</h2>
      <Link to="/dashboard/createUser" className="btn btn-primary mb-3">
        <i className="bi bi-plus-circle me-2"></i> Agregar Nuevo Usuario
      </Link>
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Email</th>
            <th>Nombre</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            user.st == "1" && ( // Solo mostramos los que no están eliminados
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.email}</td>
                <td>{user.name}</td>
                <td>
                  <Link to={`/dashboard/editUser/${user.id}`} className="btn btn-sm btn-warning me-2">
                    <i className="bi bi-pencil-square me-1"></i> Editar
                  </Link>
                  {user.role !== "admin" && (
                    <button className="btn btn-sm btn-danger" onClick={() => handleDelete(user.id)}>
                      <i className="bi bi-trash me-1"></i> Eliminar
                    </button>
                  )}
                </td>
              </tr>
            )
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListUsers;
