import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../services/api";
import Swal from "sweetalert2";

const WorkersEdit = () => {
  const { id } = useParams();
  const [worker, setWorker] = useState({
    dni: "",
    first_name: "",
    last_name: "",
    job_title: "",
    company: ""
  });
  const [photo, setPhoto] = useState(null);
  const [photoPreview, setPhotoPreview] = useState(null);
  const [loading, setLoading] = useState(!!id);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (id && id !== "0") {
      const fetchWorker = async () => {
        try {
          const response = await api.get(`/workers/getWorker/${id}`);
          setWorker(response.data);
          setLoading(false);

          if (response.data.photo) {
            setPhotoPreview(`${process.env.REACT_APP_API_URL}/workers/photo/${response.data.photo}`);
          }
        } catch (error) {
          setError("Failed to fetch worker data");
          setLoading(false);
        }
      };

      fetchWorker();
    } else {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (photo) {
      const objectUrl = URL.createObjectURL(photo);
      setPhotoPreview(objectUrl);

      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [photo]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append("dni", worker.dni);
      formData.append("first_name", worker.first_name);
      formData.append("last_name", worker.last_name);
      formData.append("job_title", worker.job_title);
      formData.append("company", worker.company);
      if (photo) {
        formData.append("photo", photo);
      }

      const endpoint = id && id !== "0" ? `/workers/updateWorker/${id}` : `/workers/createWorker`;

      const response = await api.post(endpoint, formData, {
        headers: { "Content-Type": "multipart/form-data" }
      });

      Swal.fire({
        icon: "success",
        title: response.data.message || (id && id !== "0" ? "Trabajador actualizado correctamente." : "Trabajador creado correctamente."),
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        }
      });

      // Si se acaba de crear un trabajador, navegar al modo de edición
      if (id === "0") {
        const newId = response.data.worker_id; // Asumiendo que el ID del trabajador recién creado está en `response.data.worker_id`
        navigate(`/dashboard/editWorker/${newId}`); // Redirigir al formulario de edición
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text:
          error.response?.data?.message ||
          (id && id !== "0" ? "Failed to update worker data" : "Failed to create worker")
      });
    }
  };

  const handleGoToDocuments = async () => {
    try {
      const formData = new FormData();
      formData.append("dni", worker.dni);
      formData.append("first_name", worker.first_name);
      formData.append("last_name", worker.last_name);
      formData.append("job_title", worker.job_title);
      formData.append("company", worker.company);
      if (photo) {
        formData.append("photo", photo);
      }

      const endpoint = id && id !== "0" ? `/workers/updateWorker/${id}` : `/workers/createWorker`;

      const response = await api.post(endpoint, formData, {
        headers: { "Content-Type": "multipart/form-data" }
      });

      Swal.fire({
        icon: "success",
        title: id && id !== "0" ? "Trabajador actualizado correctamente." : "Trabajador creado correctamente.",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        }
      }).then(() => {
        const newId = id === "0" ? response.data.worker_id : id;
        setTimeout(() => {
          navigate(`/dashboard/documents/${newId}`
            , {
                state: {
                  firstName: worker.first_name,
                  lastName: worker.last_name
                }
              }
          );
        }, 500);
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response?.data?.message || "Failed to save worker data"
      });
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  if (loading) return <div>Cargando...</div>;
  if (error) return <div className="alert alert-danger">{error}</div>;
  return (
    <div className="container mt-4 pb-5">
      <h2>{id && id !== "0" ? `Editar Trabajador #${id}` : "Agregar nuevo trabajador"}</h2>
      <form onSubmit={handleSubmit}>
        <div className="row mb-3">
          <div className="col">
            <div className="form-floating mb-3">
              <input
                required
                type="text"
                className="form-control"
                id="dni"
                placeholder="DNI"
                defaultValue={worker.dni}
                onChange={(e) => setWorker({ ...worker, dni: e.target.value })}
              />
              <label htmlFor="dni">DNI:</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="firstName"
                placeholder="Nombre"
                defaultValue={worker.first_name}
                onChange={(e) => setWorker({ ...worker, first_name: e.target.value })}
              />
              <label htmlFor="firstName">Nombre completo:</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="lastName"
                placeholder="Apellido"
                defaultValue={worker.last_name}
                onChange={(e) => setWorker({ ...worker, last_name: e.target.value })}
              />
              <label htmlFor="lastName">Apellido:</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="jobTitle"
                placeholder="Puesto de trabajo"
                defaultValue={worker.job_title}
                onChange={(e) => setWorker({ ...worker, job_title: e.target.value })}
              />
              <label htmlFor="jobTitle">Puesto de trabajo:</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="company"
                placeholder="Company"
                defaultValue={worker.company}
                onChange={(e) => setWorker({ ...worker, company: e.target.value })}
              />
              <label htmlFor="company">Empresa:</label>
            </div>
          </div>
          <div className="col">
            <div className="form-floating mb-3">
              <input
                type="file"
                className="form-control"
                id="photo"
                onChange={(e) => setPhoto(e.target.files[0])} // Maneja el archivo
              />
              <label htmlFor="photo">Foto:</label>
              {photoPreview && (
                <img
                  src={photoPreview} // Usa la URL del objeto para mostrar la imagen
                  alt="Worker Photo"
                  style={{ maxWidth: "300px", height: "auto", marginTop: "10px" }}
                />
              )}
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-end gap-2">
          <button type="submit" className="btn btn-primary">
            <i className="bi bi-save me-2"></i> {id && id !== "0" ? "Guardar" : "Crear"}
          </button>
          {id && id !== "0" && (
            <button type="button" className="btn btn-success" onClick={handleGoToDocuments}>
              <i className="bi bi-save me-2"></i> Guardar y continuar a documentos
            </button>
          )}
          <button type="button" className="btn btn-secondary" onClick={handleGoBack}>
            <i className="bi bi-arrow-left me-2"></i> Regresar
          </button>
        </div>
      </form>
    </div>
  );
};

export default WorkersEdit;
