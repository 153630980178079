import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import api from "../services/api"; // Asegúrate de que la ruta sea correcta

const MyDocument = ({ workerId, documentId, onIsEditedChange }) => {
  const [document, setDocument] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [fileName, setFileName] = useState("");
  const [exists, setExists] = useState(null);
  const [isEdited, setIsEdited] = useState(false); // Estado para controlar si algo se ha editado

  useEffect(() => {
    const fetchDocument = async () => {
      try {
        const response = await api.get(`/workers/getDocument/${documentId}/${workerId}`);
        setDocument(response.data);
        setFileName(response.data.file || "");
        setStartDate(response.data.start_date || "");
        setEndDate(response.data.end_date || "");
        setExists(response.data.exists || null);
        setLoading(false);
      } catch (error) {
        setDocument(null);
        setLoading(false);
      }
    };

    if (documentId) {
      fetchDocument();
    }
  }, [documentId]);

  useEffect(() => {
    // Mostrar alerta cuando el usuario intente salir si hay cambios sin guardar
    const handleBeforeUnload = (e) => {
      if (isEdited) {
        e.preventDefault();
        e.returnValue = ""; // Es necesario para que funcione en algunos navegadores
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isEdited]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setIsEdited(true); // Activar modo edición
    onIsEditedChange(true);
  };

  const handleUpload = async () => {
    if (!file) {
      Swal.fire("Error", "Please select a file to upload.", "error");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("workerId", workerId);
    formData.append("documentId", documentId);

    try {
      await api.post("/documents/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" }
      });
      Swal.fire("Success", "File uploaded successfully.", "success");
      const response = await api.get(`/documents/getDocument/${documentId}`);
      setDocument(response.data);
      setFileName(response.data.file || "");
      setFile(null); // Limpiar el archivo cargado
      setIsEdited(false); // Resetear el estado de edición
      onIsEditedChange(false);
    } catch (error) {
      Swal.fire("Error", "Failed to upload file.", "error");
    }
  };

  const handleDelete = async () => {
    const result = await Swal.fire({
      title: "Estas seguro de eliminar el archivo?",
      text: "Esta accion no puede ser recuperada.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, borrar el archivo!",
      cancelButtonText: "Cancelar"
    });

    if (result.isConfirmed) {
      try {
        await api.post(`/workers/deleteFile/${document.id}`);
        Swal.fire("Success", "El documento fue borrado correctamente.", "success");
        setFileName("");
        setFile(null);
        setIsEdited(true); // Activar modo edición
        onIsEditedChange(true);
      } catch (error) {
        Swal.fire("Error", "Failed to delete document.", "error");
      }
    }
  };

  const handleSave = async () => {
    try {
      const formData = new FormData();
      formData.append("start_date", startDate);
      formData.append("end_date", endDate);
      formData.append("exists", exists);

      if (file) {
        formData.append("file", file);
      } else {
        formData.append("file", fileName);
      }

      formData.append("workerId", workerId);
      formData.append("documentId", documentId);

      await api.post(`/workers/updateDocument/${document.id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" }
      });

      Swal.fire({
        icon: "success",
        title: "Documento actualizado correctamente",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        }
      });
      const response = await api.get(`/workers/getDocument/${documentId}/${workerId}`);
      setDocument(response.data);
      setFileName(response.data.file || "");
      setFile(null); // Limpiar el archivo cargado
      setIsEdited(false); // Resetear el estado de edición
      onIsEditedChange(false);
    } catch (error) {
      Swal.fire("Error", "Failed to update document details.", "error");
    }
  };

  const handleExistsChange = (e) => {
    setExists(e.target.value);
    setIsEdited(true); // Activar modo edición
    onIsEditedChange(true);
  };

  const handleNavigationWarning = (e) => {
    if (isEdited) {
      e.preventDefault();
      Swal.fire({
        title: "Cambios sin guardar",
        text: "Tiene cambios sin guardar, ¿desea salir sin guardar?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Salir",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = e.target.href;
        }
      });
    }
  };

  if (loading)
    return (
      <tr>
        <td colSpan={8}>Cargando...</td>
      </tr>
    );

  return (
    <tr>
      <td>{document.id}</td>
      <td className="documentTitle">
        <strong>{document?.title || "N/A"}</strong> {isEdited && <i className="bi bi-pencil"></i>}
      </td>
      <td>
        <input
          type="radio"
          id={`existsYes${document.id}`}
          name={`exists[${document.id}]`}
          value="yes"
          checked={exists === "yes"}
          onChange={handleExistsChange}
        />
      </td>
      <td>
        <input
          type="radio"
          id={`existsNo${document.id}`}
          name={`exists[${document.id}]`}
          value="no"
          checked={exists === "no"}
          onChange={handleExistsChange}
        />
      </td>
      <td>
        <input
          placeholder="Fecha de inicio"
          type="date"
          className="form-control form-control-sm"
          id="startDate"
          value={startDate}
          onChange={(e) => {
            setStartDate(e.target.value);
            setIsEdited(true); // Activar modo edición
            onIsEditedChange(true);
          }}
        />
      </td>
      <td>
        <input
          type="date"
          className="form-control form-control-sm"
          id="endDate"
          value={endDate}
          onChange={(e) => {
            setEndDate(e.target.value);
            setIsEdited(true); // Activar modo edición
            onIsEditedChange(true);
          }}
        />
      </td>
      <td>
        {!fileName && (
          <div className="upload-section">
            <input type="file" onChange={handleFileChange} />
          </div>
        )}
        {fileName && (
          <div className="actions-section">
            {fileName} {isEdited && <i className="bi bi-pencil"></i>}
            <button
              data-bs-toggle="tooltip"
              title="Borrar archivo del documento"
              onClick={handleDelete}
              className="ms-2 btn btn-sm btn-danger"
            >
              <i className="bi bi-trash"></i>
            </button>
          </div>
        )}
      </td>
      <td>
        <button
          onClick={handleSave}
          className={`btn ${isEdited ? "btn-success" : "btn-secondary"}`}
          disabled={!isEdited}
        >
          <i className="bi bi-pencil"></i> Guardar
        </button>
      </td>
    </tr>
  );
};

export default MyDocument;
