import logo from "./logo.svg";
import "./App.css";
import Hello from "./components/Hello"; // Asegúrate de que la ruta sea correcta
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import ListWorkers from './components/ListWorkers';
import WorkersEdit from './components/WorkersEdit';
import WorkersAdd from './components/WorkersAdd';
import Documents from './components/Documents';
import './styles/main.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
 
import ListUsers from './components/ListUsers';
import UserForm from './components/UserForm';

function App() {
   
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
        
          <Route path="/dashboard" element={<Dashboard />}>
            <Route index element={<ListWorkers />} /> {/* Ruta por defecto */}
            <Route path="list" element={<ListWorkers />} />
            <Route path="editWorker/:id" element={<WorkersEdit />} />
            <Route path="documents/:id" element={<Documents />} />
           
            <Route path="listUsers" element={<ListUsers />} />
            <Route path="editUser/:id" element={<UserForm />} />
            <Route path="createUser" element={<UserForm />} />

            <Route path="createWorker" element={<WorkersEdit />} />
          </Route>

          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
