import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
const Dashboard = () => {
    const [userName, setUserName] = useState(''); // Inicializa el estado con un string vacío
    const [roleName, setRoleName] = useState(''); // Inicializa el estado con un string vacío
    const navigate = useNavigate(); // Hook para redireccionar
    useEffect(() => {
        // Recupera el nombre del usuario de localStorage
        const storedNameUser = localStorage.getItem('nameUser');
        const storedRoleUser = localStorage.getItem('roleUser');
        if (storedNameUser) {
            setUserName(storedNameUser);
        }

        if (storedRoleUser) {
            setRoleName(storedRoleUser);
        }

    }, []);

    const handleLogout = () => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('nameUser'); // Asegúrate de eliminar también el nombre del usuario
        localStorage.removeItem('roleUser'); 
        window.location.href = '/login'; // Redirige a la página de login
    };

     const handleListUsuarios =() =>{
        navigate("/dashboard/listUsers");
     }

     const handleToDashboard =() =>{
        navigate("/dashboard");
     }
    return (
        <div>
            {/* Barra de navegación fija */}
            <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#"  onClick={handleToDashboard}>Dashboard</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav me-auto">
                            {/* Puedes añadir más enlaces aquí */}
                        </ul>
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="bi bi-person-circle" style={{ fontSize: '1.5rem' }}></i>
                                    <span className="ms-2">{userName}</span>
                                </a>
                                
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
                                
                                { roleName=='admin' && 
                                <li><a className="dropdown-item" href="#" onClick={handleListUsuarios}>Usuarios </a></li>
                                    }

                                    <li><a className="dropdown-item" href="#" onClick={handleLogout}>Salir </a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            {/* Área de contenido principal */}
            <main style={{ marginTop: '70px', padding: '20px' }}>
                
                <Outlet /> {/* Renderiza el componente de ruta interna aquí */}
             
            </main>

            {/* Pie de página */}
            <footer className="bg-light text-center py-3 fixed-bottom">
                <p className="mb-0">© {new Date().getFullYear()} Certificados. Todos los derechos reservados.</p>
            </footer>

            {/* Incluye los scripts de Bootstrap */}
            <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.7/dist/umd/popper.min.js" integrity="sha384-oBqDVmMz4fnFO9gybRa2xIo8K64DdM8K0L0e6Ff6N2X6xZ0/Qd7c0pX5Y6fLkA4" crossorigin="anonymous"></script>
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.min.js" integrity="sha384-kenU7K9b4l7/0nYkNC0nK67edm+pr8aNUm4H0wscMgO9e0TftFfM6j19p9Z2MYA7" crossorigin="anonymous"></script>
        </div>
    );
};

export default Dashboard;
