import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../services/api";
import Swal from "sweetalert2"; // Asegúrate de tener instalado sweetalert2

const UserForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    password: "",
    role: "user" // Por defecto, los usuarios no son admin
  });

  useEffect(() => {
    if (id) {
      api
        .get(`/workers/users/${id}`)
        .then((response) => {
          const { email, name } = response.data.data; // Asegúrate de acceder correctamente a los datos del backend
          setFormData({ ...formData, email, name });
        })
        .catch((error) => {
          // Manejo de errores
        });
    }
  }, [id]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (id) {
      // Actualizar usuario existente
      api
        .post(`/workers/users/${id}/update`, formData)
        .then((response) => {
          if (response.data.success) {
            Swal.fire({
              icon: "success",
              title: "Éxito",
              text: "Usuario actualizado exitosamente."
            }).then(() => {
              // Redirigir tras la confirmación

              setTimeout(() => {
                navigate("/dashboard/listUsers");
              }, 500);

            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: response.data.message || "No se pudo actualizar el usuario."
            });
          }
        })
        .catch((error) => {
          const errorMessage = error.response?.data?.message || "Error al actualizar el usuario.";
          Swal.fire({
            icon: "error",
            title: "Error",
            text: errorMessage
          });
        });
    } else {
      // Crear nuevo usuario
      api
        .post("/workers/users/create", formData)
        .then((response) => {
          if (response.data.success) {
            Swal.fire({
              icon: "success",
              title: "Éxito",
              text: "Usuario creado exitosamente."
            }).then(() => {
           
              setTimeout(() => {
                navigate("/dashboard/listUsers"); 
              }, 500);

            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: response.data.message || "No se pudo crear el usuario."
            });
          }
        })
        .catch((error) => {
          const errorMessage = error.response?.data?.message || "Error al crear el usuario.";
          Swal.fire({
            icon: "error",
            title: "Error",
            text: errorMessage
          });
        });
    }
  };

  return (
    <div className="container">
      <div className="  justify-content-center align-items-center  ">
        <div className="card p-4">
          <h2 className="text-center mb-4">{id ? "Editar Usuario" : "Crear Usuario"}</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-3 row">
              <label htmlFor="email" className="col-sm-4 col-form-label">
                Email
              </label>
              <div className="col-sm-8">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="name" className="col-sm-4 col-form-label">
                Nombre
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="password" className="col-sm-4 col-form-label">
                Contraseña
              </label>
              <div className="col-sm-8">
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
              </div>
            </div>
            <button type="submit" className="btn btn-primary w-100">
              {id ? "Actualizar" : "Crear"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserForm;
